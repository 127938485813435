export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salem i18n !!"])},
  "nav": {
    "Bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кәсіпке"])},
    "Individuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке тұлғаларға"])},
    "ServicesandPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервистер мен төлемдер"])},
    "ServicesandPayments1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервистер"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз туралы"])},
    "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыстар"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекен-жайымыз"])},
    "Vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бос жұмыс орындары"])}
  },
  "bussiness": {
    "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлікті сақтандыру"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мүлікті сақтандыру"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктерді сақтандыру"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауапкершілікті сақтандыру"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денсаулықты cақтандыру"])},
    "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктелген cақтандыру"])}
  },
  "individual": {
    "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автокөлікті сақтандыру"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денсаулықты сақтандыру"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мүлікті сақтандыру"])},
    "tourist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туристерді сақтандыру"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың міндетті түрлері"])},
    "bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнесті сақтандыру"])}
  },
  "service": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полис сатып алу"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полисті тексеру"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру шарттарын төлеу"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-калькулятор"])},
    "online_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн реттеу"])}
  },
  "about": {
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз туралы"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құжаттар"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пресс-центр"])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплаенс"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бос жұмыс орындары"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іс-шаралар"])},
    "ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акционерлерге"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сандық жобалар"])},
    "quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекен-жайымыз"])}
  },
  "buttons": {
    "getPresentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанияның презентациясын алу"])},
    "moreBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
    "moreBtn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыруды рәсімдеу"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания жаңалықтары"])},
    "allNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық басты жаңалықтар"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жиі қойылатын сұрақтар"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансия"])},
    "vacDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бос жұмыс орындары"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centras холдингінің өнімдері мен пайдалы қосымшалары"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тағы көрсету"])},
    "based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің мекенжайлар"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері қоңырауға тапсырыс беру"])},
    "lmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ оқыңыз"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАСКО"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франшизамен автолимит"])},
    "sakta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["САКТА"])},
    "pledged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кепілдегі автокөлік"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])},
    "polis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полисті рәсімдеу"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1997 жылдан бастап денсаулықты, мүлікті және көлікті сақтандырамыз"])},
    "showMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картада көрсету"])},
    "ans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жиі қойылатын сұрақтарға жауаптар"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарих"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рейтингтер және қауымдастықтарға қатысу"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форманы толтырыңыз"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелген өтініш"])},
    "anon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимді өтініш"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмысқа өтініш"])},
    "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спикер болу"])},
    "sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демеуші болу"])},
    "hold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акционерлердің шешімдері"])},
    "hold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акция ұстаушылар тізімі"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
    "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шешім"])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем"])},
    "decor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тіркеу"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа сұрақтар"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотипті жүктеу"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайтқа өту"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық ақпарат алу"])}
  },
  "offer": {
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақпараттық қауіпсіздік саясаты"])}
  },
  "documents": {
    "lis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаржылық есептілік"])},
    "reestr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру агенттерінің тізілімі"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мемлекеттік тіркеу"])},
    "tar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру тарифтері"])},
    "rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру ережелері (үлгілік шарттар)"])},
    "rec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректемелер"])},
    "ruleRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орыс тіліндегі инсайдерлік ақпаратты ішкі бақылау қағидалары"])},
    "ruleKz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ тіліндегі инсайдерлік ақпаратты ішкі бақылау қағидалары"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың міндетті түрлері бойынша қолданыстағы ережелер"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың ерікті түрлерінің қолданыстағы ережелері"])},
    "rule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандырудың міндетті және ерікті түрлері бойынша күші жойылған ережелер"])},
    "comissionFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссиялық сыйақылар"])}
  },
  "par": {
    "bus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күтпеген тәуекелдерден қорғау шешімімен "])},
    "bus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жеке"])},
    "bus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бизнесіңіз үшін уайымдамаңыз"])},
    "dev1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайтты жасаған компания"])},
    "dev2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Nomad"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кішірейту"])},
    "reviewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық оқу"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл таңдалды"])}
  },
  "modal": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізбен бірге үнемдеңіз"])},
    "individDisc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форманы толтырыңыз және біздің өнімдерге жеке жеңілдік алыңыз."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру түрін таңдаңыз"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түйіндемені тіркеу"])},
    "we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз сізге көмектесуге қуаныштымыз"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің мамандар Сізге кез-келген мәселені шешуге көмектеседі. Сізді қызықтыратын өнімдерді таңдап, кері қоңырауға тапсырыс беріңіз."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біздің өнімдер туралы толығырақ білгіңіз келе ме?"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біз 5 минут ішінде компания және біздің өнімдер туралы презентация жібереміз. Тек деректеріңізді қалдыруыңыз жеткілікті."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәлеметсіз бе! Мен сізге көмектесуге қуаныштымын!"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізді қызықтыратын өнімдерді таңдап, деректерді толтырыңыз. Біз сізге жақын арада хабарласамыз."])},
    "check1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дербес деректерді"])},
    "check2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["өңдеу саясатымен келісемін"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түймені басу арқылы сіз дербес деректерді"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["өңдеу шарттарымен келісесіз"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаланы таңдаңыз"])},
    "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмысқа өтініш беру"])}
  },
  "placeholder": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есімі*"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон нөмірі*"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail*"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания атауы*"])},
    "com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір (міндетті емес)"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқалардың бірін таңдаңыз"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өтініш мәтіні..."])},
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті өріс!"])},
    "reqEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате енгізілген email!"])}
  },
  "notification": {
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті өрістерді толтырыңыз"])},
    "dontagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз жеке деректерді өңдеу саясатымен келіспедіңіз"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің формаңыз сәтті жіберілді!"])},
    "reqProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті өрістер толтырылмаған немесе сақтандыру түрі табылған жоқ!"])},
    "successVacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті жіберілді! Біз сізге жақын арада хабарласамыз"])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахмет, біз сіздің ескертуіңізді ескеретін боламыз!"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің формаңыз жіберілді!"])}
  },
  "product": {
    "med": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медицина"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автокаско"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктерді сақтандыру"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мүлік"])},
    "gpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа тұлғалар алдындағы азаматтық-құқықтық жауапкершілік"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заңды тұлғаларды міндетті сақтандыру"])},
    "ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызметкерлерді қауіп-қатерден міндетті сақтандыру"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа"])},
    "gpoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Азаматтық жауапкершілік туындаған жағдайда сіздің қаржылық мүдделеріңізді қорғаймыз"])}
  },
  "medicineRespPul": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медициналық қызметкерлердің кәсіптік жауапкершілігін сақтандыру"])},
    "p_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пул әкімшісі - \"MOI (МОЙ) сақтандыру брокері\""])},
    "p_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру брокерінің қызметін жүзеге асыру құқығына ЛИЦЕНЗИЯ № 2.3.33 берілген күні 2019 жылғы 08 Ақпан"])},
    "p_three": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["МЕДИЦИНАЛЫҚ ҚЫЗМЕТКЕРЛЕРДІҢ КӘСІПТІК ЖАУАПКЕРШІЛІГІН САҚТАНДЫРУ БОЙЫНША БІРЫҢҒАЙ САҚТАНДЫРУ ПУЛІНЕ ҚАТЫСУ ТУРАЛЫ КЕЛІСІМ2024 жылғы 2 қазан"]), _normalize(["Жарамдылық мерзімі: шектеусіз"])])},
    "p_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру (қайта сақтандыру) пулының қатысушылары:"])},
    "listOfParticipant": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. «Еуразия» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. «Қазақстан Халық Банкінің Еншілес ұйымы – «Халык» Сақтандыру компаниясы» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. «Виктория» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. «Freedom Finance Insurance» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. «Jusan Garant» Сақтандыру компаниясы» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. «Қазақмыс» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. «НОМАД Иншуранс» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. «Сентрас Иншуранс» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. «Мұнай сақтандыру компаниясы» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. «ҚК ЗиМС «ИНТЕРТИЧ» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. Sinoasia B&R» СК» АҚ (Синоазия БиЭндАр)"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. «Basel» Сақтандыру компаниясы» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. «Amanat» СК» АҚ"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14. «АСКО» СК» АҚ"])}
    ],
    "p_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтандыру шартына қол жеткізу үшін пулдың интернет-ресурсына сілтеме арқылы өту қажет."])},
    "button_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқаулық"])}
  },
  "medicinePulInstructions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқаулықтар"])},
    "terminationContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шартты тоқтату"])},
    "CreatingContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шарт жасау"])},
    "CreatingAdditionalAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша келісім жасау"])},
    "CreatingPersonalAccountForIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЖК үшін жеке кабинет жасау"])},
    "CreatingPersonalAccountLegalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заңды тұлға үшін жеке кабинет жасау"])}
  },
  "carCalculator": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік құралының тозу калькуляторы"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік санаты"])},
    "motorcar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеңіл көліктер"])},
    "trucks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүк көліктері"])},
    "buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автобустар"])},
    "trolleybuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Троллейбустар мен трамвай вагондары"])},
    "trailers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүк көліктеріне арналған тіркемелер мен жартылай тіркемелер"])},
    "car_dacha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеңіл автомобильдер мен тұрғын автомобильдерге арналған тіркемелер (автомобиль-саяжай типі)"])},
    "motorcycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мотоциклдер"])},
    "scooters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скутерлер, мопедтер, мотороллерлер"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ауыл шаруашылығы тракторлары, өздігінен жүретін ауыл шаруашылығы, өрт, коммуналдық, тиеу, құрылыс, жол, жер қазу техникасы және автомобильдер базасындағы және өзге де өздігінен жүретін базалардағы өзге де техника"])},
    "bicycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Велосипедтер"])},
    "vehicleBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік маркасы"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдалану мерзімі, жылы"])},
    "period_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік құралын пайдалану мерзімі (жылдары), ондық таңбаға дейінгі дәлдікпен"])},
    "mileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік жүгірісі, мың км"])},
    "mileage_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік құралының мың км қашықтығы (мысалы, 103 200 км, онда \"103200\" бағанына енгізу қажет)"])},
    "procent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік құралдарының тозуы, %:"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептеу"])},
    "wear_vehicle_explanation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл не?"])},
    "wear_vehicle_explanation_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көлік құралының тозуы туралы"])},
    "wear_vehicle_explanation_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тозу деп, көлік құралын қалпына келтіру құнын анықтаған кезде, көлік құралын физикалық қартаюдың сандық өлшемін сыртқы және ішкі факторлардың әсерінен түсіну керек, ол жұмыс кезінде алынған және бүкіл көлік құралының және оның жеке элементтерінің (түйіндерінің, бөліктерінің) күйін сипаттайды."])}
  }
}